import React from 'react';
import { Card, Button, Accordion } from 'react-bootstrap';
import styled from "styled-components";
import { LowBudget, MidRange, HighEnd } from './lists/CasesList';

import './PartStyles.css';

import lian205m from './img/case/lian_li_205m.jpg';
import h510 from './img/case/h510.jpg';
import t220  from './img/case/icue_220t.jpg';
import fractal from './img/case/fractal.jpg';
import x465 from './img/case/icue_465x.jpg';
import h510elite from './img/case/h510_elite.jpg';

const lbImage = [
    lian205m, h510
];

const mrImage = [
    t220, fractal
];

const heImage = [
    x465, h510elite
];

const Page = styled.div`
    width: 90%;
    padding-left: 10%;
    padding-bottom: 100px;
`;

const Section = styled.h2`
    padding-top: 30px;
    text-align: center;
`;

const Image = styled.img`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    &:hover {
        transform: scale(1.03);
        cursor: pointer;
    }    
`;

const Price = styled.span`
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 10px;
`;

const Centered = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default function GPU() {
    return (
        <Page id="cases">
            <h1 className="pagePad">Cases</h1>

            {/* Low Budget Section */}
            <Section>Low Budget</Section>
            <Accordion>
                {LowBudget.map((low, index) => (
                    <Card>   
                        <Card.Header>
                            <Centered>
                                <Accordion.Toggle 
                                as={Button} 
                                variant="primary" 
                                eventKey={low.id} 
                                style={{fontSize:"16px"}}
                                >
                                    {low.name}
                                </Accordion.Toggle>                            
                            </Centered>
                        </Card.Header>
                        <Accordion.Collapse eventKey={low.id}>
                            <Card.Body style={{fontSize:"16px"}}>
                                <a href={low.link}>
                                    <Image src={lbImage[index]} width="250px" height="auto" />                                    
                                </a>
                                {low.description}
                                <br />
                                <Price>{low.price}</Price> 
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>                        
                ))}
            </Accordion>       

            {/* Mid Range Section */}
            <Section>Mid Range</Section>
            <Accordion>
                {MidRange.map((mid, index) => (
                    <Card>   
                        <Card.Header>
                            <Centered>
                                <Accordion.Toggle 
                                as={Button} 
                                variant="warning" 
                                eventKey={mid.id} 
                                style={{fontSize:"16px"}}
                                >
                                    {mid.name}
                                </Accordion.Toggle>                            
                            </Centered>
                        </Card.Header>
                        <Accordion.Collapse eventKey={mid.id}>
                            <Card.Body style={{fontSize:"16px"}}>
                                <a href={mid.link}>
                                    <Image src={mrImage[index]} width="250px" height="auto" />                                    
                                </a>
                                {mid.description}
                                <br />
                                <Price>{mid.price}</Price> 
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>                        
                ))}
            </Accordion>   


            {/* High End Section */}
            <Section>High End</Section>
            <Accordion>
                {HighEnd.map((high, index) => (
                    <Card>   
                        <Card.Header>
                            <Centered>
                                <Accordion.Toggle 
                                as={Button} 
                                variant="danger" 
                                eventKey={high.id} 
                                style={{fontSize:"16px"}}
                                >
                                    {high.name}
                                </Accordion.Toggle>                            
                            </Centered>
                        </Card.Header>
                        <Accordion.Collapse eventKey={high.id}>
                            <Card.Body style={{fontSize:"16px"}}>
                                <a href={high.link}>
                                    <Image src={heImage[index]} width="250px" height="auto" />                                    
                                </a>
                                {high.description}
                                <br />
                                <Price>{high.price}</Price> 
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>                        
                ))}
            </Accordion>  
        </Page>
    );
}
