import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import NavBar from './components/navbar/NavBar';
import About from './components/about/About';
import Help from './components/help/Help';
import NotFound from './components/notfound/NotFound'

import CPU from './components/parts/CPU';
import GPU from './components/parts/GPU';
import Cases from './components/parts/Cases';

import OsGuide from './components/guides/OsGuide';
import CpuGuide from './components/guides/CpuGuide';
import GpuGuide from './components/guides/GpuGuide';
import Monitors from './components/guides/Monitors';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

export default function App() {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <Switch>
          <Route path="/" exact component={About} />  

          <Route path="/parts/cpus" component={CPU}/>
          <Route path="/parts/gpus" component={GPU}/>  
          <Route path="/parts/cases" component={Cases}/>  

          <Route path="/guides/os" component={OsGuide} />
          <Route path="/guides/cpus" component={CpuGuide} />
          <Route path="/guides/gpus" component={GpuGuide} />
          <Route path="/guides/monitors" component={Monitors} />

          <Route path="/help" component={Help} />

          <Route component={NotFound} />
        </Switch>
      </div>      
    </Router>
  );
}
