import React from 'react';
import { Card, Button, Accordion } from 'react-bootstrap';
import styled from "styled-components";
import { LowBudget, MidRange, HighEnd } from './lists/CPUList';

import './PartStyles.css';

import ryzen1600 from './img/cpu/ryzen1600.jpg';
import intel7100 from './img/cpu/intel7100.jpg';
import intel3470 from './img/cpu/intel3470.jpg';
import intel10400 from './img/cpu/intel10400.jpg';
import intel7400 from './img/cpu/intel7400.jpg';
import ryzen3600 from './img/cpu/ryzen3600.jpg';
import intel9900k from './img/cpu/intel9900k.jpg';
import ryzen3800x from './img/cpu/ryzen3800x.jpg';
import ryzen3900xt from './img/cpu/ryzen3900xt.jpg';

const lbImage = [
    ryzen1600, intel7100, intel3470
];

const mrImage = [
    intel10400, intel7400, ryzen3600
];

const heImage = [
    intel9900k, ryzen3800x, ryzen3900xt
];

const Page = styled.div`
    width: 90%;
    padding-left: 10%;
    padding-bottom: 100px;
`;

const Section = styled.h2`
    padding-top: 30px;
    text-align: center;
`;

const Image = styled.img`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    &:hover {
        transform: scale(1.03);
        cursor: pointer;
    }    
`;

const Price = styled.span`
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 10px;
`;

const Centered = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default function CPU() {
    return (
        <Page id="cpus">
            <h1 className="pagePad">CPUs</h1>

            {/* Low Budget Section */}
            <Section>Low Budget</Section>
            <Accordion>
                {LowBudget.map((low, index) => (
                    <Card>   
                        <Card.Header>
                            <Centered>
                                <Accordion.Toggle 
                                as={Button} 
                                variant="primary" 
                                eventKey={low.id} 
                                style={{fontSize:"16px"}}
                                >
                                    {low.name}
                                </Accordion.Toggle>                            
                            </Centered>
                        </Card.Header>
                        <Accordion.Collapse eventKey={low.id}>
                            <Card.Body style={{fontSize:"16px"}}>
                                <a href={low.link}>
                                    <Image src={lbImage[index]} width="250px" height="auto" />                                    
                                </a>
                                {low.description}
                                <br />
                                <Price>{low.price}</Price> 
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>                        
                ))}
            </Accordion>       

            {/* Mid Range Section */}
            <Section>Mid Range</Section>
            <Accordion>
                {MidRange.map((mid, index) => (
                    <Card>   
                        <Card.Header>
                            <Centered>
                                <Accordion.Toggle 
                                as={Button} 
                                variant="warning" 
                                eventKey={mid.id} 
                                style={{fontSize:"16px"}}
                                >
                                    {mid.name}
                                </Accordion.Toggle>                            
                            </Centered>
                        </Card.Header>
                        <Accordion.Collapse eventKey={mid.id}>
                            <Card.Body style={{fontSize:"16px"}}>
                                <a href={mid.link}>
                                    <Image src={mrImage[index]} width="250px" height="auto" />                                    
                                </a>
                                {mid.description}
                                <br />
                                <Price>{mid.price}</Price> 
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>                        
                ))}
            </Accordion>   


            {/* High End Section */}
            <Section>High End</Section>
            <Accordion>
                {HighEnd.map((high, index) => (
                    <Card>   
                        <Card.Header>
                            <Centered>
                                <Accordion.Toggle 
                                as={Button} 
                                variant="danger" 
                                eventKey={high.id} 
                                style={{fontSize:"16px"}}
                                >
                                    {high.name}
                                </Accordion.Toggle>                            
                            </Centered>
                        </Card.Header>
                        <Accordion.Collapse eventKey={high.id}>
                            <Card.Body style={{fontSize:"16px"}}>
                                <a href={high.link}>
                                    <Image src={heImage[index]} width="250px" height="auto" />                                    
                                </a>
                                {high.description}
                                <br />
                                <Price>{high.price}</Price> 
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>                        
                ))}
            </Accordion>   

        </Page>
    );
}
