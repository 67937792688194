import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components' ;
import './styles/OS.css';

import windowslogo from './img/os/windowslogo.svg';
import linuxlogo from './img/os/linuxlogo.png';
import maclogo from './img/os/maclogo.svg';

const Title = styled.h1`
    padding-top: 5%;
    text-align: center;
    font-size: 50px;
    text-decoration: underline 1px;
`;

const Name = styled.h2`
    padding-top: 10px;
    font-size: 25px;
    color: #0275d8;
`;

const Details = styled.p`
    padding-top: 10px;
    font-size: 18px;
`;

export default function OsGuide() {
    return (
        <div id="os-guide">
            <Title>Choosing Your <br/> Operating System</Title>
            <Row id="windows" className="os-column">
                <Col md={3}>
                    <img src={windowslogo} alt="Windows Logo" width="125px" height="125px" className="os-image" />
                </Col>
                <Col md={9}>
                    <Name>Windows 10</Name>
                    <Details>
                        Windows is the most popular Operating System in the world and can be found on most machines. It is 
                        the Operating System of choice for most desktop manufacturers such as Dell, Lenovo, and HP. Most popular 
                        software products and games are also made to run on Windows. It should also be noted that due to it's 
                        popularity, malware, spyware, and ransomware are more common on machines with this OS.
                    </Details>
                </Col>
            </Row>
            <Row id="linux" className="os-column">
                <Col md={3}>
                    <img src={linuxlogo} alt="Linux Logo" width="125px" height="125px" className="os-image" />
                </Col>
                <Col md={9}>
                    <Name>Linux</Name>
                    <Details>
                    Linux is an open-source, free Operating System. You are able to customize it anyway you see fit and run it 
                    on almost any machine. There are tons of different variations available online. A drawback of this is that 
                    many popular software are not able to run on the OS. 
                    </Details>
                </Col>
            </Row>
            <Row id="macos" className="os-column">
                <Col md={3}>
                    <img src={maclogo} alt="MacOS Logo" width="125px" height="125px" className="os-image" />
                </Col>
                <Col md={9}>
                    <Name>Mac OS</Name>
                    <Details>
                        MacOS can only be run on Apple hardware. Many of its users believe that MacOS is easy to work in, installations 
                        and updates are simple, and comes with many useful, free apps. Software such as Garageband for musicians, iMovie
                        for video editors, and Keynote for presentations come preinstalled on devices. To reap the benefits of other 
                        Operating Systems, Paralles can run to create a virtual environment to run any other OS.
                    </Details>
                </Col>
            </Row>
        </div>
    )
}
