export const LowBudget = [
    {
        name: "Geforce GT 710 1GB",
        price: "Average Price: $40.99",
        description: "The GT 710 is the latest addition to the line-up of NVIDIA GeForce 700 series graphics cards. It offers creative users with moderate budget a perfect choice with stable and solid performance. The GT 710 series graphics cards enable increased productivity by supporting the use of multiple monitors. Optimized to run demanding creative software such as design and publishing programs or video editing software, it easily outperforms integrated graphics solutions.",
        link: "https://www.newegg.com/msi-geforce-gt-710-gt-710-1gd3h-lp/p/N82E16814127931?Description=gt%20710&cm_re=gt_710-_-14-127-931-_-Product&quicklink=true",
        id: "0"
    },
    {
        name: "Radeon RX 550 2GB",
        price: "Average Price: $69.99",
        description: "The power of a full size graphics card in an incredibly small package. This graphics card is perfect for small form factor systems and HTPCs as they only take up a fraction of the space of a full size graphics card. This means they will fit into virtually any system and ensure a smooth gaming experience.",
        link: "https://pcpartpicker.com/product/GLTrxr/sapphire-radeon-rx-550-4gb-pulse-video-card-11268-01-20g",
        id: "1"
    },    
    {
        name: "Geforce GT 1030 2GB",
        price: "Average Price: $88.99",
        description: "The GeForce GT 1030 comes equipped with a dual ball-bearing fan for a 2X longer card lifespan and the fan is IP5X dust-resistant for improved reliability. GeForce GT 1030 also has GPU Tweak II for intuitive performance tweaking. ",
        link: "https://www.newegg.com/gigabyte-geforce-gt-1030-gv-n1030oc-2gi/p/N82E16814932004?Description=gt%201030&cm_re=gt_1030-_-14-932-004-_-Product&quicklink=true",
        id: "2"
    
    }
];

export const MidRange = [
    {
        name: "Geforce GTX 1050 Ti 4GB",
        price: "Average Price: $139.99",
        description: "This Geforce GTX graphic card lets you customise the card to your gaming needs. Choose from OC mode, Gaming mode, Silent mode and User mode. ",
        link: "https://pcpartpicker.com/product/6hKhP6/asus-geforce-gtx-1050-ti-4gb-phoenix-video-card-ph-gtx1050ti-4g",
        id: "0"
    },
    {
        name: "Radeon RX 570 8GB",
        price: "Average Price: $169.99",
        description: "It is based on AMDs latest GCN 4 architecture designed for GloFo 14nm FinFET that delivers premium VR capability, increased level of performance, smooth VR, seamless support for next-gen gaming monitors and CPU-free game streaming or recording.",
        link: "https://www.newegg.com/asrock-radeon-rx-570-rx570-4g/p/N82E16814930010?Description=rx%20570&cm_re=rx_570-_-14-930-010-_-Product",
        id: "1"
    },    
    {
        name: "Geforce GTX 1650 Super 6GB",
        price: "Average Price: $189.99",
        description: "The GeForce GTX 16-Series Graphics Cards are powered by the all-new NVIDIA Turing architecture to give you incredible new levels of gaming realism, speed, power efficiency, and immersion. With the GeForce GTX 16-Series gaming cards you get the best gaming experience with next generation graphics performance, ice cold cooling, and advanced overclocking features.",
        link: "https://www.amazon.com/MSI-GeForce-128-Bit-Support-Graphics/dp/B08J9CF57K/ref=sr_1_3?dchild=1&keywords=1650+super&qid=1611801182&sr=8-3",
        id: "2"
    }
];

export const HighEnd = [
    {
        name: "Geforce RTX 3060 Ti 8GB",
        price: "Average Price: $409.99",
        description: "The GeForce RTX 3060 Ti lets you take on the latest games. Get incredible performance with enhanced Ray Tracing Cores and Tensor Cores, new streaming multiprocessors, and high-speed G6 memory. Combined with the next generation of design, cooling, and overclocking the EVGA GeForce RTX 3060 Ti Series redefines what it means to have The Ultimate Play.",
        link: "https://pcpartpicker.com/product/ZdpmP6/evga-geforce-rtx-3060-ti-8-gb-xc-gaming-video-card-08g-p5-3663-kr",
        id: "0"
    },
    {
        name: "Radeon RX 5700 XT 8GB",
        price: "Average Price: $799.99",
        description: "The RX 5700 XT is built for high-performance and high resolution gameplay. Experience smooth gameplay performance at 1440P and 4K. With support for 8K you are ready for next-gen displays. See everything in mind blowing detail without sacrificing performance.",
        link: "https://www.amazon.com/MSI-Gaming-Radeon-Boost-Clock/dp/B07WNSP41M/ref=sr_1_2?dchild=1&keywords=RX+5700&qid=1612489716&sr=8-2",
        id: "1"
    },    
    {
        name: "Geforce RTX 3080 10GB",
        price: "Average Price: $809.99",
        description: "The GeForce RTX 3080 delivers the unprecedented performance that gamers crave for 4K resolution gaming, powered by the NVIDIA Ampere architecture. It's built with enhanced RT Cores and Tensor Cores, new streaming multiprocessors, and super fast G6X memory for an amazing gaming experience. Combined with the next generation of design, cooling, and overclocking the GeForce RTX 3080 Series presents a new definition in ultimate performance.",
        link: "https://www.amazon.com/stores/GeForce/RTX3080_GEFORCERTX30SERIES/page/6B204EA4-AAAC-4776-82B1-D7C3BD9DDC82",
        id: "2"
    }
];
