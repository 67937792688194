import React from 'react';
import { Card, Button, Accordion } from 'react-bootstrap';
import styled from "styled-components";
import { LowBudget, MidRange, HighEnd } from './lists/GPUList';

import './PartStyles.css';

import gt710 from './img/gpu/gt710.jpg';
import rx550 from './img/gpu/rx550.jpg';
import gt1030 from './img/gpu/gt1030.jpg';
import gtx1050ti from './img/gpu/gtx1050ti.jpg';
import rx570 from './img/gpu/rx570.jpg';
import gtx1650 from './img/gpu/gtx1650.jpg';
import rtx3060ti from './img/gpu/rtx3060ti.jpg';
import rx5700 from './img/gpu/rx5700.jpg';
import rtx3080 from './img/gpu/rtx3080.jpg';

const lbImage = [
    gt710, rx550, gt1030
];

const mrImage = [
    gtx1050ti, rx570, gtx1650
];

const heImage = [
    rtx3060ti, rx5700, rtx3080
];

const Page = styled.div`
    width: 90%;
    padding-left: 10%;
    padding-bottom: 100px;
`;

const Section = styled.h2`
    padding-top: 30px;
    text-align: center;
`;

const Image = styled.img`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    &:hover {
        transform: scale(1.03);
        cursor: pointer;
    }    
`;

const Price = styled.span`
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 10px;
`;

const Centered = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default function GPU() {
    return (
        <Page id="gpus">
            <h1 className="pagePad">Graphics Cards</h1>

            {/* Low Budget Section */}
            <Section>Low Budget</Section>
            <Accordion>
                {LowBudget.map((low, index) => (
                    <Card>   
                        <Card.Header>
                            <Centered>
                                <Accordion.Toggle 
                                as={Button} 
                                variant="primary" 
                                eventKey={low.id} 
                                style={{fontSize:"16px"}}
                                >
                                    {low.name}
                                </Accordion.Toggle>                            
                            </Centered>
                        </Card.Header>
                        <Accordion.Collapse eventKey={low.id}>
                            <Card.Body style={{fontSize:"16px"}}>
                                <a href={low.link}>
                                    <Image src={lbImage[index]} width="250px" height="auto" />
                                </a>
                                {low.description}
                                <br />
                                <Price>{low.price}</Price> 
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>                        
                ))}
            </Accordion>       

            {/* Mid Range Section */}
            <Section>Mid Range</Section>
            <Accordion>
                {MidRange.map((mid, index) => (
                    <Card>   
                        <Card.Header>
                            <Centered>
                                <Accordion.Toggle 
                                as={Button} 
                                variant="warning" 
                                eventKey={mid.id} 
                                style={{fontSize:"16px"}}
                                >
                                    {mid.name}
                                </Accordion.Toggle>                            
                            </Centered>
                        </Card.Header>
                        <Accordion.Collapse eventKey={mid.id}>
                            <Card.Body style={{fontSize:"16px"}}>
                                <a href={mid.link}>
                                    <Image src={mrImage[index]} width="250px" height="auto" />                                    
                                </a>
                                {mid.description}
                                <br />
                                <Price>{mid.price}</Price> 
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>                        
                ))}
            </Accordion>   


            {/* High End Section */}
            <Section>High End</Section>
            <Accordion>
                {HighEnd.map((high, index) => (
                    <Card>   
                        <Card.Header>
                            <Centered>
                                <Accordion.Toggle 
                                as={Button} 
                                variant="danger" 
                                eventKey={high.id} 
                                style={{fontSize:"16px"}}
                                >
                                    {high.name}
                                </Accordion.Toggle>                            
                            </Centered>
                        </Card.Header>
                        <Accordion.Collapse eventKey={high.id}>
                            <Card.Body style={{fontSize:"16px"}}>
                                <a href={high.link}>
                                    <Image src={heImage[index]} width="250px" height="auto" />                                    
                                </a>
                                {high.description}
                                <br />
                                <Price>{high.price}</Price> 
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>                        
                ))}
            </Accordion>  
        </Page>
    );
}
