import React from 'react';
import { Card, Button } from 'react-bootstrap';
import './About.css';
import battlestation from './img/battlestation.svg';

const frontImage = {
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   margin: "auto",
   paddingTop: "15px",
};

export default function About() {
   return (
      <div>
         <Card id="about" className="about-section">
            <Card.Body>
               <Card.Title style={{
                  fontSize:"60px",
                  letterSpacing:"10px",
                  textAlign:"center"
               }}>
                  LET'S BUILD
               </Card.Title>
               <Card.Title variant="primary" style={{
                  fontSize:"42px",
                  wordSpacing:"20px",
                  textAlign:"center",
                  color:"#0275d8",
                  paddingTop:"5px"
               }}>
                  YOUR BATTLESTATION
               </Card.Title>
               <img src={battlestation} 
               alt="Battlestation Illustration" 
               width="175px"
               height="180px"
               style={frontImage}             
               />
               <Card.Text style={{
                  width:"65%",
                  margin:"auto",
                  fontSize:"20px",
                  lineHeight:"1.8",
                  paddingTop:"45px"
               }}>
                  There are overwhelming variety of computer parts to choose 
                  from which can make building a new PC extremely hard. This
                  website was designed to help lead you in the right direction 
                  when choosing from all the different buying options.
               </Card.Text>
               <div style={{
                  fontSize: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "35px"
               }}>
                  <Button  href="/parts/cpus" className="btn-style">
                     Start Building
                  </Button>      
               </div>
            </Card.Body>
         </Card>           
      </div>

   )
}