import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import './styles/CPU.css';

import intel10400 from './img/cpu/intel10400.jpg';
import ryzen3600 from './img/cpu/ryzen3600.jpg';
import ryzen3800x from './img/cpu/ryzen3800x.jpg';

const Title = styled.h1`
    padding-top: 5%;
    text-align: center;
    font-size: 50px;
    text-decoration: underline 1px;
`;

export default function CpuGuide() {
    return (
        <div id="cpu-guide" style={{
            width: "90%",
            height: "100%",
            paddingLeft: "10%",
            paddingBottom: "100px"
        }}>
            <Title>Choosing A CPU</Title>
            <p className="cpu-details">
                The CPU is the most important part of your computer. If you will mainly be using your 
                desktop to run applications such as Microsoft Office, or any other productivity apps then 
                a low budget processor will work just fine. 
            </p>            
            <p className="cpu-details">
                Getting a better CPU can great influence how well your computer handles heavy tasks such 
                as Gaming, Video Production, and 3D rendering. The deeper you dive into each of these 
                areas, the better processor you may need.
            </p>
            <Row style={{
                width: "80%",
                margin: "auto",
                paddingLeft: "10%",
                paddingTop: "25px"
            }}
            >
                <Col md={4}>
                    <img src={ryzen3600} alt="Ryzen 5 3600" width="175px" height="150px" style={{padding:"20px"}} />
                </Col>                
                <Col md={4}>
                    <img src={intel10400} alt="Intel 5 10400" width="175px" height="200px" style={{padding:"20px"}} />
                </Col>
                <Col md={4}>
                    <img src={ryzen3800x} alt="Ryzen 7 3800x" width="150px" height="150px" style={{padding:"20px"}} />
                </Col>
            </Row>
        </div>
    )
}
