export const LowBudget = [
    {
        name: "Lian Li Lancool 205M",
        price: "Average Price: $59.99",
        link: "https://www.amazon.com/Lian-Water-Cooling-Pre-Installed-LANCOOL-205/dp/B085DHZ2SW",
        id: "0"
    },
    {
        name: "NZXT H510",
        price: "Average Price: $69.99",
        link: "https://www.amazon.com/NZXT-H510-Management-Water-Cooling-Construction/dp/B07TD9C5HS/ref=sr_1_2?dchild=1&keywords=NZXT+H510&qid=1612490135&s=electronics&sr=1-2",
        id: "1"
    }    
];

export const MidRange = [
    {
        name: "Corsair iCUE 220T",
        price: "Average Price: $109.99",
        link: "https://www.amazon.com/Corsair-Airflow-Tempered-Glass-Mid-Tower/dp/B07RW8GLQJ/ref=sr_1_1?dchild=1&keywords=Corsair+iCUE+220T&qid=1612490156&s=electronics&sr=1-1",
        id: "0"

    },
    {
        name: "Fractal Design Meshify C",
        price: "Average Price: $99.99",
        link: "https://www.amazon.com/Fractal-Design-Meshify-TG-Lt/dp/B07B3JDBM2/ref=sr_1_2_mod_primary_new?dchild=1&keywords=Fractal+Design+Meshify+C&qid=1612490172&s=electronics&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=1-2",
        id: "1"

    }   
];

export const HighEnd = [
    {
        name: "Corsair iCUE 465X RGB",
        price: "Average Price: $149.99",
        link: "https://www.amazon.com/Corsair-Icue-Mid-Tower-Smart-Black/dp/B07W6RCKWF/ref=sr_1_3?dchild=1&keywords=Corsair+iCUE+465X+RGB&qid=1612490199&s=electronics&sr=1-3",
        id: "0"

    },
    {
        name: "NZXT H510 Elite",
        price: "Average Price: $154.99",
        link: "https://www.amazon.com/NZXT-H510-Elite-Dual-Tempered-Water-Cooling/dp/B07TD9VTVQ/ref=sr_1_3?dchild=1&keywords=NZXT+H510+Elite&qid=1612490212&s=electronics&sr=1-3",
        id: "1"

    }
];
