export const LowBudget = [
    {
        name: "AMD Ryzen 5 1600",
        price: "Average Price: $149.99",
        description: "The Ryzen 5 1600 brings six cores and twelve threads at a great price point that places it in direct contention with Intel's four-core Kaby Lake lineup.",
        link: "https://www.amazon.com/AMD-Processor-Wraith-Stealth-Cooler/dp/B07XTQZJ28/ref=sr_1_1?dchild=1&keywords=AMD+Ryzen+5+1600&qid=1612490449&s=electronics&sr=1-1",
        id: "0"
    },
    {
        name: "Intel i3-7100",
        price: "Average Price: $103.99",
        description: "Equipped with rapid responsiveness, the 7th generation Intel® Core™ processor has the power and speed to keep up with your demands. Open files and programs quickly, plus seamlessly switch between applications and web pages without delay.",
        link: "https://www.amazon.com/Intel-i3-7100-Desktop-Processor-BX80677I37100/dp/B01NCESRJX/ref=sr_1_2?dchild=1&keywords=Intel+i3-7100&qid=1612490473&s=electronics&sr=1-2",
        id: "1"
    },
    {
        name: "Intel i5-3470",
        price: "Average Price: $79.99",
        description: "With intelligent performance that accelerates in response to demanding tasks, such as playing games and editing photos, the Intel Core i5 processor moves faster when you do. The Intel Core i5 processor automatically allocates processing power where it's needed most.",
        link: "https://www.amazon.com/Intel-Core-i5-3470-Quad-Core-Processor/dp/B0087EVHVW/ref=sr_1_2?dchild=1&keywords=Intel+i5-3470&qid=1612490486&s=electronics&sr=1-2",
        id: "2"
    }
];

export const MidRange = [
    {
        name: "Intel i5 10400",
        price: "Average Price: $169.99",
        description: "10th Gen Intel Core i5-10400 desktop processor optimized for gaming, creating, and productivity. Only compatible with 400 series chipset based motherboard.",
        link: "https://www.amazon.com/Intel-i5-10400-Desktop-Processor-LGA1200/dp/B086MN38Q2/ref=sr_1_3?dchild=1&keywords=Intel+i5+10400&qid=1612490612&s=electronics&sr=1-3",
        id: "0"
    },
    {
        name: "Intel i5 7400",
        price: "Average Price: $180.99",
        description: "The Intel i5–7400 is based on the Kaby-Lake design, is a quad-core processor operating at 3GHz with turbo mode at 3.5Ghz, and has four 256MB L2 caches and a 6MB L3 cache.",
        link: "https://www.amazon.com/Intel-BX80677I57400-Core-Desktop-Processors/dp/B01MSTDS3N/ref=sr_1_1_mod_primary_new?dchild=1&keywords=Intel+i5+7400&qid=1612490628&s=electronics&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=1-1",
        id: "1"
    },
    {
        name: "AMD Ryzen 5 3600",
        price: "Average Price: $199.99",
        description: "3rd Gen AMD Ryzen processors are built using the world's most advanced 7nm manufacturing technology. Paired with Wraith coolers, AMD Ryzen processors deliver game-winning performance while keeping your system astonishingly cool and quiet. Ryzen processors neural-net intelligence can adapt to your workload.",
        link: "https://www.amazon.com/AMD-Ryzen-3-6GHz-Cache-Desktop/dp/B081TL8XTM/ref=sr_1_2?dchild=1&keywords=ryzen+5+3600&qid=1613018229&sr=8-2",
        id: "2"
    }
];

export const HighEnd = [
    {
        name: "Intel i9 9900K",
        price: "Average Price: $439.99",
        description: "When paired with Intel Optane memory, accelerates the loading and launching of the games you play. With up to 5GHz and 16-way multitasking, take your creativity to the next level with the power you need to create, edit, and share.",
        link: "https://www.amazon.com/Intel-i9-9900K-Processor-Unlocked-BX806849900K/dp/B089J731BX/ref=sr_1_2?dchild=1&keywords=Intel+i9+9900K&qid=1612490766&s=electronics&sr=1-2",
        id: "0"
    },
    {
        name: "AMD Ryzen 7 3800X",
        price: "Average Price: $399.99",
        description: "Experience elite performance in games, content creation, and intense multi-tasking. A beautifully balanced design for serious PC enthusiasts.",
        link: "https://www.amazon.com/AMD-Ryzen-3800XT-16-Threads-Processor/dp/B089WCXZJC/ref=sr_1_1?dchild=1&keywords=ryzen+7+3800x&qid=1613018320&sr=8-1",
        id: "1"
    },
    {
        name: "AMD Ryzen 9 3900XT",
        price: "Average Price: $499.99",
        description: "3rd Gen AMD Ryzen 9 3900XT processors offer no-compromise performance for gamers and creators who deserve to have it all. Do more of what you love with 12 cores at clock speeds of up to 4.7GHz, full overclocking supports, and 70MB of GameCache.",
        link: "https://www.amazon.com/AMD-Ryzen-3900XT-24-Threads-Processor/dp/B089WD454D/ref=sr_1_1?dchild=1&keywords=ryzen+9+3900&qid=1613018369&sr=8-1",
        id: "2"
    }
];
