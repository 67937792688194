import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import styled from 'styled-components';
import './NavBar.css';

const URL = styled.span`
    padding: 15px;
    font-size: 18px;        
    color: black;
    &:hover {
        color: white;
    }
`;

export default function NavBar() {
   return (
      <Navbar collapseOnSelect variant="dark" bg="primary" expand="md">
         <Navbar.Brand href="/" style={{
            fontSize: "30px",
            color: "black",
            paddingLeft: "25px"
         }}>
            Choose My Parts
         </Navbar.Brand>
         <Navbar.Toggle aria-controls="nav-burger-menu" style={{fontSize:"20px", backgroundColor:"black"}} />
         <Navbar.Collapse id="full-navbar">
            <Nav className="ml-auto">
               <Nav.Link href="/"><URL>About</URL></Nav.Link>
               <NavDropdown title={<span className="dropdown-style">Part Recommendations</span>} id="parts-dropdown">
                  <NavDropdown.Item href="/parts/cpus" style={{fontSize:"16px"}}>CPUs</NavDropdown.Item>
                  <NavDropdown.Item href="/parts/gpus" style={{fontSize:"16px"}}>Graphics Cards</NavDropdown.Item>
                  <NavDropdown.Item href="/parts/cases" style={{fontSize:"16px"}}>Cases</NavDropdown.Item>
               </NavDropdown>
               <NavDropdown title={<span className="dropdown-style">Guides</span>} id="guides-dropdown">
                  <NavDropdown.Item href="/guides/os" style={{fontSize:"16px"}}>Operating Systems</NavDropdown.Item>
                  <NavDropdown.Item href="/guides/cpus" style={{fontSize:"16px"}}>CPUs</NavDropdown.Item>
                  <NavDropdown.Item href="/guides/gpus" style={{fontSize:"16px"}}>Graphics Cards</NavDropdown.Item>
                  <NavDropdown.Item href="/guides/monitors" style={{fontSize:"16px"}}>Monitors</NavDropdown.Item>
               </NavDropdown>
               <Nav.Link href="/help"><URL>Help</URL></Nav.Link>
            </Nav>
         </Navbar.Collapse>
      </Navbar>
   );
}

/*
   Add Guide Section:
   <Nav.Link href="/guides"><URL>Guides</URL></Nav.Link>
*/