import React from 'react';
import styled from 'styled-components';
import './Help.css';
//import Contact from '../contact/Contact';
import picker from './img/pcPicker.png';

const Compatible = styled.div`
    width: 90%;
    padding-left: 10%;
    padding-bottom: 35px;
`;

export default function Help() {
    return (
        <div>
            <Compatible>
                <h1 className="help-title">Compatibility</h1>
                <p className="part-picker">
                    Want to make sure your chosen parts are compatible?
                    <br />
                    Try using a website like <a href="https://pcpartpicker.com/">PC Part Picker</a> 
                </p>
                <a href="https://pcpartpicker.com/">
                    <img src={picker} 
                    alt="PC Part Picker Logo" 
                    width="100px" 
                    height="90px" 
                    className="picker-logo" 
                    />   
                </a>
            </Compatible>
        </div>
    )
}
