import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import './styles/GPU.css';

import gtx1050ti from './img/gpu/gtx1050ti.jpg';
import rx570 from './img/gpu/rx570.jpg';
import rx5700 from './img/gpu/rx5700.jpg';

const Title = styled.h1`
    padding-top: 5%;
    text-align: center;
    font-size: 50px;
    text-decoration: underline 1px;
`;

export default function GpuGuide() {
    return (
        <div id="gpu-guide" style={{
            width: "90%",
            height: "100%",
            paddingLeft: "10%",
            paddingBottom: "100px"
        }}>
            <Title>Choosing A <br/> Graphics Card</Title>
            <p className="gpu-details">
                The better the Graphics Card, the more information that can be rendered in a shorter amount of 
                time. This would be very import if you are trying to run the newest games, video or music production, 
                or 3d rendering.
            </p>
            <p className="gpu-details">
                However, if you will be using your computer to perform light tasks such as run business applications, 
                productivity applications, emails, or browsing you may not need a Graphics Card at all. Most software
                will let you know the specs neccessary to run them.
            </p>
            <Row style={{
                width: "80%",
                margin: "auto",
                paddingLeft: "10%",
                paddingTop: "25px"
            }}
            >
                <Col md={4}>
                    <img src={rx570} alt="Radeon RX 570" width="200px" height="149px" style={{padding:"20px"}} />
                </Col>                
                <Col md={4}>
                    <img src={gtx1050ti} alt="Geforce GTX 1050ti" width="200px" height="151px" style={{padding:"20px"}} />
                </Col>
                <Col md={4}>
                    <img src={rx5700} alt="Radeon RX 5700" width="200px" height="184px" style={{padding:"20px"}} />
                </Col>
            </Row>
        </div>
    )
}
