import React from 'react';
import { Row, Col} from 'react-bootstrap';
import styled from 'styled-components';
import './styles/Monitors.css';

import desk from './img/monitors/desk.svg';

const Title = styled.h1`
    padding-top: 5%;
    text-align: center;
    font-size: 50px;
    text-decoration: underline 1px;
`;

const Feature = styled.h2`
    padding-top: 5%;
    text-align: center;
    font-size: 36px;
    color: #0275d8;
`;

const Details = styled.p`
    padding-top: 10px;
    font-size: 18px;
`;

export default function Monitors() {
    return (
        <div id="monitor-guide">
            <Title>Monitors</Title>
            <img src={desk} alt="Person sitting at monitor" width="200px" height="200px" className="desk-image" />
    
            <Row>
                <Col md={3}>
                    <Feature>Screen Size</Feature>
                </Col>
                <Col md={9}>
                    <Details>
                        For the most part, 24 in. monitors are the most commonly used in any workplace. However, monitors 
                        between 27 in. and 30 in. are becoming increasing popular. It allows you have more tabs and possibly 
                        more than one window open side by side. You can have a web browser on one side and Micrsoft Word on 
                        the other. This means less clicking between tasks and an increase productivity. 
                    </Details>
                </Col>
            </Row>
                
            <Row>
                <Col md={3}>
                    <Feature>Resolution</Feature>
                </Col>
                <Col md={9}>
                    <Details>
                        The standard for for most computer setups today is 1080p on a 24 in. monitor. However, the resolution 
                        you should use may be determined by the size of your monitor. Too high of a resolution on a small screen 
                        can often be annoying because it shrinks all images down. You will also need a stronger graphics card
                        to support high resolutions. 4K can often be more enjoyable when you're viewing things such as movies.
                        1440p is a nice middle-ground between 1080p and 4K while not being overly taxing on your computer.
                    </Details>
                </Col>
            </Row>

            <Row>
                <Col md={3}>
                    <Feature>Refresh Rate</Feature>
                </Col>
                <Col md={9}>
                    <Details>
                        The refresh rate of your monitor refers to how many times per second the screen refreshes the image on it.
                        Majority of monitors in any workplace will have a 60hz refresh rate. Get a monitor with the highest 
                        refresh rate that you can afford and that your GPU can handle. For gamers, a higher refresh rate can 
                        make gameplay feel more "smoother"
                    </Details>
                </Col>
            </Row>
            {/* 
            <Row>
                <Col md={3}>
                    <Feature>Aspect Ratio</Feature>
                </Col>
                <Col md={9}>
                    <Details>
                    
                    </Details>
                </Col>
            </Row>
            */}
        </div>
    )
}